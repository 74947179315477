/* https://github.com/hankchizljaw/modern-css-reset */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");


:root {
  --font-size-base: 1.0rem;
  --font-size-title: 1.3rem;
  --header-height: 43px;
}

body {
  font-family: 'Malgun Gothic', sans-serif;
}

* *::before *::after {
  box-sizing: border-box;
}

input:focus::placeholder {
  color: transparent !important;
}

 /* Remove default focus styles in Firefox */
:focus-visible {
  outline: none;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

#root {
  min-height: calc(100vh + 1px);
}

/* CSS RESET  */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* font-size: 100%; */
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
textarea {
  all: unset;
  box-sizing: border-box;
}

button {
  all: unset;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}
